import styles from './UserDetailsPage.module.scss';
import OrdersIcon from '../../assets/images/user_details_icons/Group 39730_user_details_box.svg';
import OrdersIconWhite from '../../assets/images/user_details_icons/Group 39730_user_details_box_white.svg';
import AccountIcon from '../../assets/images/user_details_icons/Vector_account_icon.svg';
import AccountIconWhite from '../../assets/images/user_details_icons/Vector_account_icon_white.svg';
import AddressIcon from '../../assets/images/user_details_icons/Group_user_group_address_black.svg';
import AddressIconWhite from '../../assets/images/user_details_icons/Group_user_group_address.svg';
import RewardsIcon from '../../assets/images/user_details_icons/medal-award-prize-winner-reward_user_details.svg';
import RewardsIconWhite from '../../assets/images/user_details_icons/medal-award-prize-winner-reward_user_details_white.svg';
import HelpIcon from '../../assets/images/user_details_icons/Group_help_user_details.svg';
import HelpIconWhite from '../../assets/images/user_details_icons/Group_help_user_details_white.svg';
import SignOutIcon from '../../assets/images/user_details_icons/Group 39717_sign_out.svg';
import SignOutIconWhite from '../../assets/images/user_details_icons/Group 39717_sign_out_white.svg';
import { useEffect, useState } from 'react';

interface UserDetailsSideNavProps {
   tabSwitchFunction : (tab:string) => void;
   isFullWidth?: boolean;
   isHidden?: boolean;
}

const UserDetailsSideNav = ({tabSwitchFunction,isFullWidth=false,isHidden=false} : UserDetailsSideNavProps) => {
   const [activeTab,setActiveTab] = useState<string>(isFullWidth ? '' :'account')

   useEffect(() => {
      if(isFullWidth){
         setActiveTab('');
      }
   },[isFullWidth])
  return (
    <div className={isFullWidth ? styles.sidenav_full_width_container : isHidden ?  styles.empty_sidenav_container : styles.sidenav_container}>
        <div className={styles.sidenav_title_container}>
          <div>Hi, Buddy</div>
          <div>Welcome to ARR fine foods</div>
        </div>
        <div className={isFullWidth ? styles.sidenav_list_container: styles.sidenav_list}>
            <div className={`${styles.sidenav_item} ${activeTab === 'orders' && styles.active_tab}`} onClick={() => {setActiveTab('orders');tabSwitchFunction('orders')}}>
               <img src={activeTab === 'orders' ? OrdersIconWhite:OrdersIcon} className={styles.sidenav_icon}/>
               <div>Orders & Purchases</div>
            </div>
            <div className={`${styles.sidenav_item} ${activeTab === 'account' && styles.active_tab}`}  onClick={() => {setActiveTab('account');tabSwitchFunction('account')}}>
               <img src={activeTab === 'account' ? AccountIconWhite : AccountIcon} className={styles.sidenav_icon}/>
               <div>Account Details</div>
            </div>
            <div className={`${styles.sidenav_item} ${activeTab === 'address' && styles.active_tab}`}  onClick={() => {setActiveTab('address');tabSwitchFunction('address')}}>
               <img src={activeTab === 'address' ? AddressIconWhite : AddressIcon} className={styles.sidenav_icon}/>
               <div>Address</div>
            </div>
            <div className={`${styles.sidenav_item} ${activeTab === 'rewards' && styles.active_tab}`}  onClick={() => {setActiveTab('rewards');tabSwitchFunction('rewards')}}>
               <img src={activeTab === 'rewards' ? RewardsIconWhite : RewardsIcon} className={styles.sidenav_icon}/>
               <div>Rewards</div>
            </div>
            <div className={`${styles.sidenav_item} ${activeTab === 'help' && styles.active_tab}`}  onClick={() => {setActiveTab('help');tabSwitchFunction('help')}}>
               <img src={activeTab === 'help' ? HelpIconWhite : HelpIcon} className={styles.sidenav_icon}/>
               <div>Help</div>
            </div>
            <div className={`${styles.sidenav_item} ${activeTab === 'signout' && styles.active_tab}`}  onClick={() => {setActiveTab('signout');tabSwitchFunction('signout')}}>
               <img src={activeTab === 'signout' ? SignOutIconWhite : SignOutIcon} className={styles.sidenav_icon}/>
               <div>Signout</div>
            </div>
        </div>
    </div>
  )
}

export default UserDetailsSideNav