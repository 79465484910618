import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import React, { useEffect, useState } from 'react'
import styles from './productPage.module.scss'

export interface ProductCounterType {
    productCountCallback : (count:number) => void;
    count : number,
    className ?: string,
    fromCart ?: boolean
}

const ProductCounter = ({productCountCallback,count,className='',fromCart=false}:ProductCounterType) => {

    const [productCount,setProductCount] = useState<number>(count)

    useEffect(() => {
        productCountCallback(productCount);
    },[productCount])
 
  return (
    <div className={`${styles.counter_container} ${className}`}>
     { ((!fromCart && productCount > 0) || (fromCart && productCount > 1)) && <div onClick={() => { 
          setProductCount((prev) => prev - 1);
        }}><MinusOutlined className={productCount === 0 ? styles.button_disabled : ''} /></div>}
        <div>{productCount}</div>
        <div onClick={() => {
            setProductCount((prev) => prev + 1);    
        }}><PlusOutlined /></div>
    </div>
  )
}

export default ProductCounter