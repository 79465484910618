import FilterIcon from '../../assets/images/user_details_icons/Group 39723_filter_icon.svg'
import styles from './UserDetailsPage.module.scss';
import CornFlakes from '../../assets/images/image 50_cornflakes.svg';
import RightOutlined from '@ant-design/icons/lib/icons/RightOutlined';
import CheckCircleTwoTone from '@ant-design/icons/lib/icons/CheckCircleTwoTone';
import { useEffect, useState } from 'react';

interface OrdersPageProps {
  ordersData: any;
}

const OrdersPage = ({ordersData}:OrdersPageProps) => {

  console.log("Orders Page",ordersData)

  const [orderDetailsArray,setOrderDetailsArray] = useState<any>([]);

  useEffect(() => {
    
    const resultArray = ordersData.map((order:any) => {
      console.log("Order",order)
       const productList = (order.productList).map((product:any) => {
        return {
          productImage : product.productImage ? product.productImage : '',
          productName: product.productName,
        }
       });
       return {
        orderId: order.orderId,
        price: order.totalPrice,
        productList: productList
       }
    })


    setOrderDetailsArray(resultArray);
    

  },[ordersData])

  return (
    <div>
      <div className={styles.address_section_heading}>
        <div>Orders & Purchases</div>
        <div className={styles.filter_section}>
          <img src={FilterIcon} />
          <div>Filter Order</div>
        </div>
      </div>
      <div className={styles.orders_section_container}>
        <div className={styles.order_detail_container}>
          <div>All Orders</div>
          <div className={styles.product_list_container}>
            {
              orderDetailsArray.map((order:any) => {
                return <div className={styles.product_container}>
                  <div className={styles.product_name_container}>
                    <div>Order Id: </div>
                    <div>{order.orderId}</div>
                  </div>
                  <div className={styles.order_details_image_container}>
                       {
                        order.productList.map((product:any) => {
                          return <div className={styles.product_image_container}><img src={product.productImage} className={styles.product_image}/></div>
                        })
                       }
                  </div>
                  <div className={styles.product_price_container}><div>{`\u00A3`}{order.price}</div><RightOutlined /></div>
                </div>
              })
            }
          </div>

        </div>
        {/* <div className={styles.product_list_container}>
          <div className={styles.product_container}>
            <div className={styles.product_image_container}>
              <img src={CornFlakes} className={styles.product_image}/>
            </div>
            <div className={styles.product_name_container}>
              <div>Cadbury Dairy Milk Minis Chocolate Home Treats Pack</div>
              <div style={{display:'flex',flexDirection:'row',gap:'5px'}}><CheckCircleTwoTone twoToneColor="#52c41a"/>Delivered</div>
            </div>
            <div className={styles.product_price_container}>
              <div>$124.00</div>
              <RightOutlined />
            </div>
          </div>
          <div className={styles.product_container}>
            <div className={styles.product_image_container}>
              <img src={CornFlakes} className={styles.product_image}/>
            </div>
            <div className={styles.product_name_container}>
              <div>Cadbury Dairy Milk Minis Chocolate Home Treats Pack</div>
              <div style={{display:'flex',flexDirection:'row',gap:'5px'}}><CheckCircleTwoTone twoToneColor="#52c41a"/>Delivered</div>
            </div>
            <div className={styles.product_price_container}>
              <div>$124.00</div>
              <RightOutlined />
            </div>
          </div>
          <div className={styles.product_container}>
            <div className={styles.product_image_container}>
              <img src={CornFlakes} className={styles.product_image}/>
            </div>
            <div className={styles.product_name_container}>
              <div>Cadbury Dairy Milk Minis Chocolate Home Treats Pack</div>
              <div style={{display:'flex',flexDirection:'row',gap:'5px'}}><CheckCircleTwoTone twoToneColor="#52c41a"/>Delivered</div>
            </div>
            <div className={styles.product_price_container}>
              <div>$124.00</div>
              <RightOutlined />
            </div>
          </div>
        </div> */}
      </div>
    </div>
  )
}

export default OrdersPage