import { Dropdown, MenuProps, Rate, Select, Spin } from 'antd';
import CornFlakes from '../../assets/images/image 50_cornflakes.svg';
import styles from './productPage.module.scss';
import Button from '../../components/Button/Button';
import ProductListing from '../ProductListing/ProductListing';
import TeaBox from '../../assets/images/image 48_taj_mahal.png';
import DownOutlined from '@ant-design/icons/lib/icons/DownOutlined';
import { useContext, useEffect, useRef, useState } from 'react';
import { CartContext } from '../../config/context/CartContext';
import { useNavigate, useParams } from 'react-router-dom';
import { getListofProducts, getProductById, getProductsInCategory } from '../../config/axios/productApis';
import { ArrowLeftOutlined, LoadingOutlined } from '@ant-design/icons';
import ProductCounter from './ProductCounter';

const ProductPage = () => {

  const {productId} = useParams();
  const topElementRef = useRef<any>(null);
  const navigate = useNavigate();
  const {addToCart} = useContext(CartContext);
  const [productQuantity, setProductQuantity] = useState<number>(1);
  const [pageLoader,setPageLoader] = useState<boolean>(false);
  const [productDetails,setProductDetails] = useState<any>({
    imageUrl:'',
    title:'',
    description:'',
    price:0,
    ratings: 0,
    unit: '',
    manufacturerDetails: '',
    marketedBy: '',
    countryOfOrigin: '',
    productId: '',

  });

  const [productList,setProductList] = useState<any>([]);

  useEffect(() => {
    if(productId){
      if (topElementRef.current) {
        topElementRef.current.scrollIntoView({ behavior: 'smooth' });
      }
      setPageLoader(true);
    getProductById(productId?.toString()).then((productDetailsResponse:any) => {
      setProductDetails({
        imageUrl: productDetailsResponse.data.productImage,
        title: productDetailsResponse.data.productName,
        description: productDetailsResponse.data.description,
        price: productDetailsResponse.data.price,
        ratings: productDetailsResponse.data.ratings,
        unit: productDetailsResponse.data.units,
        manufacturerDetails: productDetailsResponse.data.manufacturerDetails,
        marketedBy: productDetailsResponse.data.marketedBy,
        countryOfOrigin: productDetailsResponse.data.countryOfOrigin,
        productId: productDetailsResponse.data.productId
      })
      getProductsInCategory(productDetailsResponse.data.categoryId.toString(),{minPrice:'0',maxPrice:'10000'},4,0).then((productsResponse:any) => {
        const responseArray = productsResponse.data.content.map((product:any) => {
          return {
            title: product.productName,
            description: product.description,
            price: product.price,
            id: product.productId,
            imageUrl: product.productImage
          }
        })
        setProductList(responseArray)
      })
      setPageLoader(false);
    })


  }
    
  },[productId])

    const items = [
        {
          label: 1,
          key: '1',
          value: 1
        },
        {
          label: 2,
          key: '2',
          value: 2
        },
        {
          label: 3,
          key: '3',
          value: 3
        },
      ];
  
  return (
    <div ref={topElementRef}>
       {pageLoader ? <Spin tip='Loading' size='large' className={styles.page_loader}/> : <> 
        <div>
          <div className={styles.back_button} onClick={() => navigate(-1) }>
            <ArrowLeftOutlined />
            <span>Back</span>
          </div>
            <div className={styles.product_image_container}>
                {/* <div className={styles.product_images_container}>
                   <div className={styles.product_small_container}> 
                        <img src={productDetails.imageUrl} alt="Corn Flakes" className={styles.product_small_image}/>
                  </div>
                  <div className={styles.product_small_container}> 
                        <img src={productDetails.imageUrl} alt="Corn Flakes" className={styles.product_small_image}/>
                  </div>
                  <div className={styles.product_small_container}> 
                        <img src={productDetails.imageUrl} alt="Corn Flakes" className={styles.product_small_image}/>
                  </div>
                  <div className={styles.product_small_container}> 
                        <img src={productDetails.imageUrl} alt="Corn Flakes" className={styles.product_small_image}/>
                  </div>
                </div> */}
                <div className={styles.large_image_container}>
                    <img src={productDetails.imageUrl} alt="Corn Flakes" className={styles.product_large_image}/>
                </div>
                <div className={styles.product_image_details_container}>
                        <div className={styles.best_seller_label}>Best Seller</div>
                        <div className={styles.product_card_title}>{productDetails.title}</div>
                        <div>{productDetails.description}</div>
                       { productDetails.ratings && <div className={styles.ratings_container}>
                            <Rate defaultValue={0} value={productDetails.ratings} disabled/>
                            <div>
                                <div>({productDetails.ratings})</div>
                                <div>31 reviews</div>
                            </div>
                        </div>}
                        <div className={styles.price_container}>
                            <div>{`\u00A3`} {productDetails.price}</div>
                            <div>Price when purchased online</div>
                        </div>
                        <div style={{display:'flex',gap:'10px',fontSize:'14px'}}>
                          <div><b>Unit:</b> </div>
                          {productDetails.unit}
                        </div>
                        <div className={styles.product_page_button_container}>
                            <ProductCounter count={productQuantity} productCountCallback={(count) => setProductQuantity(count) } className={styles.product_page_counter}/>
                            <Button text='Add to cart' onClick={() => {addToCart({id:productDetails.productId,price:+(productDetails.price * productQuantity).toFixed(2),quantity:productQuantity === 0 ? 1 : productQuantity,title:productDetails.title,description:productDetails.description,imageUrl:productDetails.imageUrl},productQuantity === 0 ? 1 : productQuantity)}} className={styles.add_cart_button}/>
                        </div>

                </div>
            </div>
            <div className={styles.product_details_heading}><b>Product Details</b></div>
            <div className={styles.product_details_container}>
              {productDetails.description}
              <div className={styles.product_detail_indi_container}>
                <div><b>Customer Care Details</b></div>
                <div>Email: info@arrfinefoods.com</div>

              </div>
              <div className={styles.product_detail_indi_container}>
                <div><b>Return Policy</b></div>
                <div>This Item is non-returnable. For a damaged, defective, incorrect or expired item, you can request a replacement within 72 hours of delivery.In case of an incorrect item, you may raise a replacement or return request only if the item is sealed/ unopened/ unused and in original condition.</div>
              </div>

            </div>
        </div>
        <div className={styles.similar_items_container}>
          <div className={styles.similar_items_text}>
            <h4><b>Similar items you might like</b></h4>
            <div>Based on what customers bought</div>
          </div>
          <ProductListing data={productList}/>
        </div>
       </>}
    </div>
  )
}

export default ProductPage