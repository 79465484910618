// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.refundPolicy_refund_policy_container__-Q9a5 {
  padding: 50px;
  display: flex;
  flex-direction: column;
  gap: 50px;
}
.refundPolicy_refund_policy_container_title__kN8h1 {
  align-self: center;
  font-size: 20px;
  font-weight: bold;
  color: black;
}
.refundPolicy_refund_policy_container_heading__rPCLr {
  display: flex;
  gap: 20px;
  flex-direction: column;
}
.refundPolicy_refund_policy_container_heading__rPCLr > p {
  color: #515d66;
  line-height: 1.7;
  font-size: 14px;
}
.refundPolicy_refund_policy_container__-Q9a5 > div {
  color: #515d66;
  line-height: 1.7;
  font-size: 14px;
}
.refundPolicy_refund_policy_container__-Q9a5 > div > b {
  font-weight: bold;
  color: black;
}

.refundPolicy_refund_policy_subcontainer__YeWpu {
  display: flex;
  gap: 20px;
  flex-direction: column;
}
.refundPolicy_refund_policy_subcontainer__YeWpu > div {
  color: #515d66;
  line-height: 1.7;
  font-size: 14px;
  display: flex;
  gap: 20px;
  flex-direction: column;
}
.refundPolicy_refund_policy_subcontainer__YeWpu > div > b {
  font-weight: bold;
  color: black;
}`, "",{"version":3,"sources":["webpack://./src/views/PolicyPage/RefundPolicy/refundPolicy.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AACJ;AACI;EACI,kBAAA;EACA,eAAA;EACA,iBAAA;EACA,YAAA;AACR;AAEI;EACI,aAAA;EACA,SAAA;EACA,sBAAA;AAAR;AAEQ;EACC,cAAA;EACA,gBAAA;EACA,eAAA;AAAT;AAIK;EACG,cAAA;EACA,gBAAA;EACA,eAAA;AAFR;AAIQ;EACI,iBAAA;EACA,YAAA;AAFZ;;AAOA;EACI,aAAA;EACA,SAAA;EACA,sBAAA;AAJJ;AAMI;EACI,cAAA;EACA,gBAAA;EACA,eAAA;EACA,aAAA;EACA,SAAA;EACA,sBAAA;AAJR;AAKQ;EACI,iBAAA;EACA,YAAA;AAHZ","sourcesContent":[".refund_policy_container{\r\n    padding: 50px;\r\n    display: flex;\r\n    flex-direction: column;\r\n    gap: 50px;\r\n\r\n    &_title{\r\n        align-self: center;\r\n        font-size: 20px;\r\n        font-weight: bold;\r\n        color: black;\r\n    }\r\n\r\n    &_heading{\r\n        display: flex;\r\n        gap: 20px;\r\n        flex-direction: column;\r\n \r\n        >p{\r\n         color: #515d66;\r\n         line-height: 1.7;\r\n         font-size: 14px;\r\n        }\r\n     }\r\n\r\n     >div{\r\n        color: #515d66;\r\n        line-height: 1.7;\r\n        font-size: 14px;\r\n\r\n        >b{\r\n            font-weight: bold;\r\n            color: black;\r\n        }\r\n     }\r\n}\r\n\r\n.refund_policy_subcontainer{\r\n    display: flex;\r\n    gap: 20px;\r\n    flex-direction: column;\r\n\r\n    >div{\r\n        color: #515d66;\r\n        line-height: 1.7;\r\n        font-size: 14px;\r\n        display: flex;\r\n        gap: 20px;\r\n        flex-direction: column;\r\n        >b{\r\n            font-weight: bold;\r\n            color: black;\r\n        }\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"refund_policy_container": `refundPolicy_refund_policy_container__-Q9a5`,
	"refund_policy_container_title": `refundPolicy_refund_policy_container_title__kN8h1`,
	"refund_policy_container_heading": `refundPolicy_refund_policy_container_heading__rPCLr`,
	"refund_policy_subcontainer": `refundPolicy_refund_policy_subcontainer__YeWpu`
};
export default ___CSS_LOADER_EXPORT___;
