import {Select } from "antd"
import BillSummary from "../../components/BillSummary/BillSummary"
import styles from './cartPage.module.scss'
import { useLocation } from "react-router-dom"
import { useContext, useEffect, useState } from "react"
import { CartContext, WishListContext } from "../../config/context/CartContext"
import ProductCounter from "../ProductPage/ProductCounter"
import dayjs from "dayjs"



const CartPage = () => {
     const getDeliveryTime = () => {
            // Define the cutoff time (15:00 hrs)
            const cutoffTime = dayjs().set('hour', 15).set('minute', 0).set('second', 0);
    
            // Get the current system time
            const currentTime = dayjs();
    
            if (currentTime.isBefore(cutoffTime)) {
                // If the order is placed before 15:00 hrs, schedule delivery for the same day
                return dayjs().format('MMMM D, YYYY');
              } else {
                // If the order is placed after 15:00 hrs, schedule delivery for the next day
                return dayjs().add(1, 'day').format('MMMM D, YYYY');
              }
        }
      const location = useLocation();
      const isWishList = location?.state?.isWishList
      const {cartItems,removeFromCart,addToCartFromCartPage,getCartTotal,getCartTotalItems,shipmentFee} = useContext(CartContext);
      const {wishListItems,removeFromwishList,addTowishListFromwishListPage} = useContext(WishListContext);

    return (
        <div className={styles.cart_page_container}>
            {!isWishList && cartItems.length > 0 ? <div className={styles.cart_list_container}>
                <div className={styles.cart_list_heading}>
                    <div>{`Shopping Cart(${cartItems.length})`}</div>
                    {<div>Shipping, arrives {getDeliveryTime()}</div>}
                </div>
                <div className={styles.cart_product_list_container}>
                    <div className={styles.cart_heading_container}>
                        <div>Products</div>
                        <div>Qty</div>
                        {/* {isWishList && <div>Stock Status</div>} */}
                        <div>Price</div>
                    </div>
                    {cartItems.map((cartItem:any) => {
                        return <div className={styles.cart_product_container}>
                           <div className={styles.cart_product_image_container}>
                             <img src={cartItem?.imageUrl} className={styles.tea_box_image} />
                            </div> 
                            <div className={isWishList ? `${styles.cart_product_details_container_wishlist}`:`${styles.cart_product_details_container}`}>
                            <div>{cartItem?.title}</div>
                            <ProductCounter count={cartItem.quantity} productCountCallback={(value) => addToCartFromCartPage(cartItem,value)} fromCart={true} />
                        </div>
                        {/* {isWishList && <div className={styles.stock_status_details}>IN STOCK</div>} */}
                        <div className={styles.cart_product_price_container}>
                            <div>{`\u00A3`} {(cartItem.quantity * cartItem?.price).toFixed(2)}</div>
                            <div onClick={() => {isWishList ? removeFromwishList(cartItem):removeFromCart(cartItem)}} style={{cursor:"pointer"}}>Remove</div>
                        </div>

                        </div>
                    })}
                    {/* <div className={styles.cart_product_container}>
                        <div className={styles.cart_product_image_container}>
                           <img src={TeaBox} className={styles.tea_box_image} /> 
                        </div>
                        <div className={isWishList ? `${styles.cart_product_details_container_wishlist}`:`${styles.cart_product_details_container}`}>
                            <div>Cadbury Dairy Milk Minis Chocolate Home Treats Pack</div>
                            <Dropdown trigger={['click']} menu={{items}}><div className={styles.product_quantity_container}>1 <DownOutlined /></div></Dropdown>
                        </div>
                        {isWishList && <div className={styles.stock_status_details}>IN STOCK</div>}
                        <div className={styles.cart_product_price_container}>
                            <div>$124.00</div>
                            <div>Remove</div>
                        </div>
                    </div>
                    <div className={styles.cart_product_container}>
                        <div className={styles.cart_product_image_container}>
                           <img src={TeaBox} className={styles.tea_box_image} /> 
                        </div>
                        <div className={isWishList ? `${styles.cart_product_details_container_wishlist}`:`${styles.cart_product_details_container}`}>
                            <div>Cadbury Dairy Milk Minis Chocolate Home Treats Pack</div>
                            <Dropdown trigger={['click']} menu={{items}}><div className={styles.product_quantity_container}>1 <DownOutlined /></div></Dropdown>
                        </div>
                        {isWishList && <div className={styles.stock_status_details}>IN STOCK</div>}
                        <div className={styles.cart_product_price_container}>
                            <div>$124.00</div>
                            <div>Remove</div>
                        </div>
                    </div>
                    <div className={styles.cart_product_container}>
                        <div className={styles.cart_product_image_container}>
                           <img src={TeaBox} className={styles.tea_box_image} /> 
                        </div>
                        <div className={isWishList ? `${styles.cart_product_details_container_wishlist}`:`${styles.cart_product_details_container}`}>
                            <div>Cadbury Dairy Milk Minis Chocolate Home Treats Pack</div>
                            <Dropdown trigger={['click']} menu={{items}}><div className={styles.product_quantity_container}>1 <DownOutlined /></div></Dropdown>
                        </div>
                        {isWishList && <div className={styles.stock_status_details}>IN STOCK</div>}
                        <div className={styles.cart_product_price_container}>
                            <div>$124.00</div>
                            <div>Remove</div>
                        </div>
                    </div> */}
                </div>
                
            </div> : isWishList && wishListItems.length > 0 ? <div className={styles.cart_list_container}>
                <div className={styles.cart_list_heading}>
                    <div>{`My List (${wishListItems.length})`}</div>
                    {<div>Shipping, arrives Fri, Jun 17</div>}
                </div>
                <div className={styles.cart_product_list_container}>
                    <div className={styles.cart_heading_container}>
                        <div>Products</div>
                        <div>Qty</div>
                        {isWishList && <div>Stock Status</div>}
                        <div>Price</div>
                    </div>
                    {wishListItems.map((cartItem:any) => {
                        return <div className={styles.cart_product_container}>
                           <div className={styles.cart_product_image_container}>
                             <img src={cartItem?.imageUrl} className={styles.tea_box_image} />
                            </div> 
                            <div className={isWishList ? `${styles.cart_product_details_container_wishlist}`:`${styles.cart_product_details_container}`}>
                            <div>{cartItem?.title}</div>
                                <ProductCounter count={cartItem.quantity} productCountCallback={(value) => addToCartFromCartPage(cartItem,value)} />
                        </div>
                        {isWishList && <div className={styles.stock_status_details}>IN STOCK</div>}
                        <div className={styles.cart_product_price_container}>
                            <div>{`\u00A3`} {cartItem.quantity * cartItem?.price}</div>
                            <div onClick={() => {isWishList ? removeFromwishList(cartItem):removeFromCart(cartItem)}}>Remove</div>
                        </div>

                        </div>
                    })}
                    {/* <div className={styles.cart_product_container}>
                        <div className={styles.cart_product_image_container}>
                           <img src={TeaBox} className={styles.tea_box_image} /> 
                        </div>
                        <div className={isWishList ? `${styles.cart_product_details_container_wishlist}`:`${styles.cart_product_details_container}`}>
                            <div>Cadbury Dairy Milk Minis Chocolate Home Treats Pack</div>
                            <Dropdown trigger={['click']} menu={{items}}><div className={styles.product_quantity_container}>1 <DownOutlined /></div></Dropdown>
                        </div>
                        {isWishList && <div className={styles.stock_status_details}>IN STOCK</div>}
                        <div className={styles.cart_product_price_container}>
                            <div>$124.00</div>
                            <div>Remove</div>
                        </div>
                    </div>
                    <div className={styles.cart_product_container}>
                        <div className={styles.cart_product_image_container}>
                           <img src={TeaBox} className={styles.tea_box_image} /> 
                        </div>
                        <div className={isWishList ? `${styles.cart_product_details_container_wishlist}`:`${styles.cart_product_details_container}`}>
                            <div>Cadbury Dairy Milk Minis Chocolate Home Treats Pack</div>
                            <Dropdown trigger={['click']} menu={{items}}><div className={styles.product_quantity_container}>1 <DownOutlined /></div></Dropdown>
                        </div>
                        {isWishList && <div className={styles.stock_status_details}>IN STOCK</div>}
                        <div className={styles.cart_product_price_container}>
                            <div>$124.00</div>
                            <div>Remove</div>
                        </div>
                    </div>
                    <div className={styles.cart_product_container}>
                        <div className={styles.cart_product_image_container}>
                           <img src={TeaBox} className={styles.tea_box_image} /> 
                        </div>
                        <div className={isWishList ? `${styles.cart_product_details_container_wishlist}`:`${styles.cart_product_details_container}`}>
                            <div>Cadbury Dairy Milk Minis Chocolate Home Treats Pack</div>
                            <Dropdown trigger={['click']} menu={{items}}><div className={styles.product_quantity_container}>1 <DownOutlined /></div></Dropdown>
                        </div>
                        {isWishList && <div className={styles.stock_status_details}>IN STOCK</div>}
                        <div className={styles.cart_product_price_container}>
                            <div>$124.00</div>
                            <div>Remove</div>
                        </div>
                    </div> */}
                </div>
                
            </div> :  <div className={styles.no_items_message}>No Items in Cart. Please add items</div>}
            {!isWishList && cartItems.length > 0 && <BillSummary isButtonAvailable={true} billDetails={{
               totalPrice: getCartTotal(),
               totalQuantity: getCartTotalItems(),
               shipmentFee: shipmentFee
            }}/>}
        </div>
  )
}

export default CartPage