import { Button, Form, Spin } from 'antd'
import styles from './UserDetailsPage.module.scss';
import Input from 'antd/es/input/Input';
import { useEffect, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';


interface AccountDetailsProps{
  userData: any;
}

interface UserAccountProps{
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  password: string;
  cnf_password: string;
}

const AccountDetails = ({userData}:AccountDetailsProps) => {
  const [form] = Form.useForm();

  useEffect(() => {
    console.log('userData changed:', userData);
    form.setFieldsValue({
      firstName: userData.firstName || '',
      lastName: userData.lastName || '',
      phoneNumber: userData.phoneNumber || '',
      email: userData.email || '',
      password: userData.password || '',
    });
  }, [userData, form]);

  return (
    <Form form={form}>
      <div className={styles.form_fields_container}>
        <div>
          <div className={styles.form_field_label}>First Name</div>
          <Form.Item name="firstName">
            <Input
              className={styles.form_field_input}
              placeholder="Enter First Name"
            />
          </Form.Item>
        </div>
        <div>
          <div className={styles.form_field_label}>Last Name</div>
          <Form.Item name="lastName">
            <Input
              className={styles.form_field_input}
              placeholder="Enter Last Name"
            />
          </Form.Item>
        </div>
      </div>
      <div className={styles.form_fields_container}>
        <div>
          <div className={styles.form_field_label}>Phone Number</div>
          <Form.Item name="phoneNumber">
            <Input
              className={styles.form_field_input}
              type="number"
              placeholder="Enter Phone Number"
            />
          </Form.Item>
        </div>
        <div>
          <div className={styles.form_field_label}>Email</div>
          <Form.Item name="email">
            <Input
              className={styles.form_field_input}
              type="email"
              placeholder="Enter email"
            />
          </Form.Item>
        </div>
      </div>
      <div className={styles.form_fields_container}>
        <div>
          <div className={styles.form_field_label}>Password</div>
          <Form.Item name="password">
            <Input
              className={styles.form_field_input}
              type="password"
              disabled
            />
          </Form.Item>
        </div>
        <div>
          <div className={styles.form_field_label}>Confirm Password</div>
          <Form.Item name="cnf_password">
            <Input
              className={styles.form_field_input}
              type="password"
            />
          </Form.Item>
        </div>
      </div>
      <div className={styles.form_buttons_container}>
        <Button
          type="primary"
          htmlType="submit"
          className={styles.form_submit_button}
        >
          Save
        </Button>
        <Button className={styles.form_submit_button}>Cancel</Button>
      </div>
    </Form>
  );
}

export default AccountDetails