import React, { useEffect, useState } from 'react'
import styles from './categoriesPage.module.scss';
import { ProductListProps } from '../HomePage/HomePage';
import { getProductById, getProductCategoryById, getProductsInCategory } from '../../config/axios/productApis';
import { Button, Input, Pagination, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import ProductListing from '../ProductListing/ProductListing';

const MultipackPage = () => {
    const [minPrice, setMinPrice] = useState<string>('');
    const [maxPrice, setMaxPrice] = useState<string>('');
    const [pageSize,setPageSize] = useState<number>(16);
    const [filteredArray,setFilteredArray] = useState<ProductListProps[]>([]);
    const [productList, setProductList] = useState<ProductListProps[]>([{
      imageUrl: '',
      title: '',
      description: '',
      price: 0,
      id: '',
    }])
    const [paginationData, setPaginationData] = useState<ProductListProps[]>([
        {imageUrl: '',
        title: '',
        description: '',
        price: 0,
        id: '',}
      ])
    
      const [filteredPaginationData, setFilteredPaginationData] = useState<ProductListProps[]>([
        {imageUrl: '',
        title: '',
        description: '',
        price: 0,
        id: '',}
      ])
    
      const pageSizeOptions = [
        '16','32','48','56'
      ]
    
      const pageHandler = (page: number,pageSize:number) => {
        setPage(page);
        setPageSize(pageSize);
      }
    
      const [page, setPage] = useState<number>(1);
      const [categoryListLoader, setCategoryListLoader] = useState<boolean>(false);

      useEffect(() => {
        setPage(1);
        setCategoryListLoader(true);
        getProductCategoryById('15').then((productCategory:any) => {
            if(productCategory && productCategory.data.arrayOfProduct !== null){
              const valueArray: any[] = [];
              const productArray = JSON.parse(productCategory.data.arrayOfProduct);
              Promise.all(
                productArray.map((productId: number) => 
                  getProductById(productId.toString()).then((product: any) => {
                    const productData = product.data;
                    return {
                      title: productData.productName,
                      description: productData.description,
                      price: productData.price,
                      id: productData.productId,
                      imageUrl: productData.productImage
                    };
                  })
                )
              ).then((results) => {
                valueArray.push(...results);
                setProductList(valueArray);
                setCategoryListLoader(false);
              }).catch((error) => {
                console.error("Error fetching products:", error);
              });
              
          }else{
            setProductList([]);
          }
          })
      },[])

      useEffect(() => {
        if(productList.length > 0){
        const startIndex = (page - 1) * pageSize;
        const endIndex = startIndex + pageSize;
        setPaginationData(productList.slice(startIndex, endIndex));
        }
      },[page,productList,pageSize])
    
      useEffect(() => {
        if(filteredArray.length > 0){
          const startIndex = (page - 1) * pageSize;
          const endIndex = startIndex + pageSize;
          setFilteredPaginationData(filteredArray.slice(startIndex, endIndex));
          }
      },[page,filteredArray,pageSize])

      const filterData = () => {
        if(minPrice === '' && maxPrice === ''){
          return;
        }
         getProductsInCategory('15',{
          minPrice: minPrice,
          maxPrice: maxPrice,
        },100).then((filteredResponse:any) => {
          const resultArray = filteredResponse.data.content.map((product:any) => {
            return {
              title: product.productName,
              description: product.description,
              price: product.price,
              id: product.productId,
              imageUrl: product.productImage
            }
          })
          setFilteredArray(resultArray);
        })
      }
      const clearData = () => {
        setMinPrice('');
        setMaxPrice('');
        setFilteredArray([]);
      }
    
    
  return (
    <div className={styles.categories_page_container}>
        <div className={styles.categories_header_container}>
            <div className={styles.veggies_text}>
                        <div><b>Multipacks!</b></div>
                        <div>Unleash the Shopper's Valor: Where Every Cart is a chariot and Every Fresh,Healthy purchase a Triumph</div>
            </div>
        </div>
        { <div className={styles.categories_container}>
      { <div className={styles.filter_checkbox_container}>
        <div>
          <div><b>Price Range</b></div>
          <div className={styles.price_range_filter_container}>
           <div>
             <label>Min price</label>
             <Input type='number' min={0} onChange={(e) => {setMinPrice(e.target.value)}}/>
           </div>
           <div>
             <label>Max price</label>
             <Input type='number' min={minPrice}  onChange={(e) => {setMaxPrice(e.target.value)}}/>
           </div>
          </div>
          <Button className={styles.filter_button} onClick={() => {filteredArray.length === 0 ? filterData() : clearData()}}>{filteredArray.length > 0 ? 'Clear Filter' : 'Filter'}</Button>
        </div>
      </div>}
      {categoryListLoader ? <Spin 
    indicator={<LoadingOutlined />}
   style={{marginLeft: '30%',marginTop:'10%'}}
   size='large'
    spinning/> :<div className={styles.product_grid_for_mobile}>
        <ProductListing data={filteredArray.length > 0 ? filteredPaginationData : paginationData}/>
        <Pagination defaultPageSize={16} onChange={pageHandler} total={productList.length} style={{position:'absolute',right:'20px'}} current={page} pageSizeOptions={pageSizeOptions}/>
      </div>}
    </div>}


    </div>
  )
}

export default MultipackPage