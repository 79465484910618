import Button from '../../components/Button/Button'
import styles from './homepage.module.scss'
import cn from 'classnames';
import BreadImage from '../../assets/images/Rectangle 44_snickers.png';

const NewArrivals = (props:any) => {
  return (
    <div className={styles.new_arrivals_container}>
      <div className={styles.new_arrivals_head}>
        <div className={styles.best_sellers_text}>
            <div>Weekly Specials</div>
            <div>Discover this week's top picks for fresh and delicious foods.</div>
        </div>
        <Button text='View All' onClick={() => {}} className={cn(styles.view_all_button,styles.best_sellers_mobile)} />
      </div>
      <div className={styles.new_arrivals_grid}>
         <img className={styles.new_arrivals_first_container} src={props.imageData.find((image:any) => image.imageId === 4)?.imageUrl}/>
        {!props.isMobile && <div className={styles.grid_mid_container}>
           <img src={props.imageData.find((image:any) => image.imageId === 5)?.imageUrl} />
           <div className={styles.mid_horizontal_container}>
              <img src={props.imageData.find((image:any) => image.imageId === 6)?.imageUrl} className={styles.mid_first_image}/>
              <img src={BreadImage}/>
           </div>
        </div>}
        <img className={styles.meat_pic_container} src={props.imageData.find((image:any) => image.imageId === 7)?.imageUrl}/>
      </div>
    </div>
  )
}

export default NewArrivals