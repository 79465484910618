// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.shipmentPolicy_shipment_policy_container__tac3W {
  padding: 50px;
  display: flex;
  flex-direction: column;
  gap: 50px;
}
.shipmentPolicy_shipment_policy_container__tac3W > div {
  display: flex;
  gap: 20px;
  flex-direction: column;
}
.shipmentPolicy_shipment_policy_container__tac3W > div .shipmentPolicy_shipment_policy_subheading__knnvd {
  font-size: 16px;
  font-weight: bold;
}
.shipmentPolicy_shipment_policy_container__tac3W > div > ul > li {
  color: #515d66;
  line-height: 1.7;
  font-size: 14px;
}
.shipmentPolicy_shipment_policy_container_heading__sdHS- {
  display: flex;
  gap: 20px;
  flex-direction: column;
}
.shipmentPolicy_shipment_policy_container_heading__sdHS- > div:nth-child(2) {
  color: #515d66;
  line-height: 1.7;
  font-size: 14px;
}

.shipmentPolicy_shipment_policy_title__xx0AB {
  align-self: center;
  font-size: 20px;
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/views/PolicyPage/ShipmentPolicy/shipmentPolicy.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AACJ;AACI;EACI,aAAA;EACA,SAAA;EACA,sBAAA;AACR;AACQ;EACI,eAAA;EACA,iBAAA;AACZ;AAEQ;EACI,cAAA;EACJ,gBAAA;EACA,eAAA;AAAR;AAII;EACG,aAAA;EACA,SAAA;EACA,sBAAA;AAFP;AAIO;EACC,cAAA;EACA,gBAAA;EACA,eAAA;AAFR;;AAOA;EACI,kBAAA;EACA,eAAA;EACA,iBAAA;AAJJ","sourcesContent":[".shipment_policy_container{\r\n    padding: 50px;\r\n    display: flex;\r\n    flex-direction: column;\r\n    gap: 50px;\r\n\r\n    >div{\r\n        display: flex;\r\n        gap: 20px;\r\n        flex-direction: column; \r\n\r\n        .shipment_policy_subheading{\r\n            font-size: 16px;\r\n            font-weight: bold;\r\n        }\r\n\r\n        >ul>li{\r\n            color: #515d66;\r\n        line-height: 1.7;\r\n        font-size: 14px;\r\n        }\r\n    }\r\n\r\n    &_heading{\r\n       display: flex;\r\n       gap: 20px;\r\n       flex-direction: column;\r\n\r\n       >div:nth-child(2){\r\n        color: #515d66;\r\n        line-height: 1.7;\r\n        font-size: 14px;\r\n       }\r\n    }\r\n}\r\n\r\n.shipment_policy_title{\r\n    align-self: center;\r\n    font-size: 20px;\r\n    font-weight: bold;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"shipment_policy_container": `shipmentPolicy_shipment_policy_container__tac3W`,
	"shipment_policy_subheading": `shipmentPolicy_shipment_policy_subheading__knnvd`,
	"shipment_policy_container_heading": `shipmentPolicy_shipment_policy_container_heading__sdHS-`,
	"shipment_policy_title": `shipmentPolicy_shipment_policy_title__xx0AB`
};
export default ___CSS_LOADER_EXPORT___;
