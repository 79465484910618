import { Button, Checkbox, Input } from "antd";
import NewUserPrompt from '../../assets/images/Group 39653_signup_prompt.svg';
import styles from './login.module.scss';
import { useState } from "react";
import Signup from "./Signup";
import { loginUser } from "../../config/axios/userApis";
import { useNavigate } from "react-router-dom";

import 'react-toastify/dist/ReactToastify.css';
import { toast } from "../../components/Toaster/ToasterComponent";

const Login = () => {

    const [signupPage, setSignUpPage] = useState<boolean>(false);
    const [userName, setUserName] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [loginSuccess, setLoginSuccess] = useState<boolean>(false);
    const [loginLoader, setLoginLoader] = useState<boolean>(false);
    const navigate = useNavigate();

    if(signupPage){
        return <Signup redirectBack={() => setSignUpPage(false)}/>
    }

    const loginFunction = async () => {
        try{
            setLoginLoader(true);
        const loginResponse:any = await loginUser(userName, password);
        if (loginResponse.status === 200){
            setLoginSuccess(true);
            window.alert("Login successful!");
            localStorage.setItem('token', JSON.stringify(loginResponse.data.details.accessToken));
            localStorage.setItem('userId',JSON.stringify(loginResponse.data.details.userId));
            setLoginLoader(false);
            if (window.history?.length && window.history.length > 1) {
                navigate(-1);
             } else {
                navigate('/user', { replace: true });
             }
             window.location.reload();
        }
        else{
            toast.error(loginResponse?.data?.description, {
                position: "top-right",
                });
            setLoginLoader(false);
        }
    }catch(e:any){
        toast.error("Login is unsuccessful. Please try again", {
            position: "top-right",
            });
            setLoginLoader(false);
    }
    }

  return (
    <div className={styles.login_container}>
        <div className={styles.login_title}>Sign In</div>
        <div className={styles.login_details_container}>
            <div className={styles.login_form_container}>
                <div>
                    <div>Email</div>
                    <Input type="email" onChange={(e) => setUserName(e.target.value)}/>
                </div>
                <div>
                    <div>Password</div>
                    <Input type="password" onChange={(e) => {setPassword(e.target.value)}}/>
                </div>
            </div>
            <div className={styles.forgot_password} onClick={() => navigate('/forgotpassword')}><u>Forgot Password ?</u></div>
        </div>
        <div className={styles.remember_me_container}>
            <Checkbox />
            <div>Remember Me ?</div>
        </div>
        <div className={styles.security_prompt}>For your security, we do not recommend checking this box if you are using a public device.</div>
        <div className={styles.button_container}>
            <Button type="primary" onClick={() => loginFunction()} disabled={userName === '' || password === ''} loading={loginLoader}>Sign In</Button>
            <img src={NewUserPrompt} />
            <Button onClick={() => setSignUpPage(true)}>Create Account</Button>

        </div>
        {loginSuccess && <div style={{color:'green'}}>Login Success !! Redirecting back ...</div>}
    </div>
  )
}

export default Login