import styles from './shipmentPolicy.module.scss';

const ShipmentPolicy = () => {
  return (
    <div className={styles.shipment_policy_container}>
        <div className={styles.shipment_policy_container_heading}>
            <div className={styles.shipment_policy_title}>Shipping Policy</div>
            <div>At ArrFineFoods, we aim to deliver your orders promptly and efficiently, ensuring the freshness and quality of your products. Below are the details of our shipping process:</div>
        </div>
        <div>
            <div className={styles.shipment_policy_subheading}>Delivery Areas</div>
            <ul>
                <li>We currently offer delivery services within a 3km radius of Plymouth, UK.</li>
                <li>Delivery is available to residential and business addresses in this area.</li>
            </ul>
        </div>
        <div>
            <div className={styles.shipment_policy_subheading}>Delivery Charges</div>
            <ul>
                <li>Free Delivery for all orders above £35.</li>
                <li>For orders below £35, a standard delivery charge of £4.99 applies.</li>
            </ul>
        </div>
        <div>
            <div className={styles.shipment_policy_subheading}>Delivery Times</div>
            <ul>
                <li>Orders placed before 12:00 PM (noon) will typically be delivered on the same day.</li>
                <li>Orders placed after 12:00 PM will be delivered the next working day.</li>
                <li>Deliveries are made between 9:00 AM and 6:00 PM from Monday to Saturday.</li>
            </ul>
        </div>
        <div>
            <div className={styles.shipment_policy_subheading}>Order Cutoff and Processing</div>
            <ul>
                <li>Orders must be placed by 12:00 PM (noon) for same-day delivery.</li>
                <li>Orders received after the cutoff time will be processed on the next business day.</li>
                <li>No deliveries are made on Sundays or public holidays.</li>
            </ul>
        </div>

    </div>
  )
}

export default ShipmentPolicy