import { Button, Input } from 'antd'
import styles from './login.module.scss'
import { useState } from 'react'

type ResetPasswordProps = {
    resetPasswordHandler : (password: string) =>  void
}

const ResetPassword = ({resetPasswordHandler}:ResetPasswordProps) => {
    const [password, setPassword] = useState<string>('');

  return (
    <div className={styles.login_container}>
        <div className={styles.forgot_password_heading_container}>
            <div className={styles.login_title}>Reset Password</div>
            <div>Choose a strong and secure password to protect your account.</div>
        </div>
        <div>
        <div className={styles.forgot_password_field}>
            <div className={styles.forgot_password_label}>Enter Password</div>
            <Input type='password' onChange={(e) => {setPassword(e.target.value)}}/>
        </div>
        <div className={styles.forgot_password_field}>
            <div className={styles.forgot_password_label}>Re Enter Password</div>
            <div>
            <Input type='password' onChange={(e) => {}}/>
            </div>
        </div>
        </div>
        <Button type='primary' onClick={() => {resetPasswordHandler(password)}} >Reset Password</Button>

    </div>
  )
}

export default ResetPassword