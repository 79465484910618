import styles from './aboutPage.module.scss'

const AboutPage = () => {
  return (
    <div className={styles.about_page_container}>
        <div className={styles.about_page_header}>About Us</div>
        <div className={styles.about_page_content}> 
        <p>Arr fine foods - the trading name of Pride Asia Food Ltd specializes in selling asian groceries and vegetables. based in Plymouth, we also have a supermarket in 14-16 Victoria Rd, St Budeaux, Plymouth PL5 1RG, United Kingdom. With a massive demand from our UK customers, we are now expanding the shopping experience to a whole new level. www.arrfinefoods.com - an online shop, where you can find over 1000+ products from hundreds of brands. We are the leading online supermarket. We stock products from various parts of the world and offer our customers a great range of native products from fresh fruits, vegetables, and leaves to rice, dals, spices, and seasonings to nuts and snacks and ready to eat meals.</p>
        <p>Handpicked products of premium quality at the lowest prices. Stop roaming around for meat, poultry, eggs, dairy, vegetables, cereals, grains like rice, wheat, flour, pulses, confectioneries, snacks, and household items as arrfinefoods.com offers everything under one roof. With specialized sections for Asian, South Indian, North Indian, Srilankan, Oriental, Afro-Caribbean, Pakistani products arrfinefoods online shop covers all range of grocery items around the globe at a reasonable cost.</p>
        <p>Arrfinefoods also have a dedicated inventory for sea-foods imported from the Indian ocean. The ocean delicacies include fishes like kingfish, cuttlefish, Anchovies, Indian Oil Sardines, Barracuda, Red Snapper, and others such as Prawns and Crabs. We follow a special packing method which keeps the frozen products in the same condition as you buy from the shop.</p>
        <p>With more than 5 years of ethnic food importing and retailing. All our products are handpicked and undergo strict quality control measures to ensure the best quality of products. Our clientele are mostly people who migrated from native to UK, are regular buyers of ARR fine food products for their home to retain the back home flavor.</p>
        </div>
    </div>
  )
}

export default AboutPage