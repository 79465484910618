import { Button, Input } from 'antd'
import styles from './login.module.scss'
import { useState } from 'react'

type VerifyEmailAddressPageProps = {
    verifyHandler : (code:string) =>  void
}

const VerifyEmailAddressPage = ({verifyHandler}:VerifyEmailAddressPageProps) => {
    const [verificationCode, setVerificationCode] = useState<string>('');
  return (
    <div className={styles.login_container}>
        <div className={styles.forgot_password_heading_container}>
            <div className={styles.login_title}>Verify Email Address</div>
            <div>Complete your registration by verifying your email address and unlock the full potential of your account with us.</div>
        </div>
        <div className={styles.forgot_password_field}>
            <div className={styles.forgot_password_label}>Verification Code</div>
            <Input type='number' onChange={(e) => setVerificationCode(e.target.value)}/>
        </div>
        <Button type='primary' onClick={() => {verifyHandler(verificationCode)}} disabled={verificationCode === ''}>Verify Me</Button>

    </div>
  )
}

export default VerifyEmailAddressPage