// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contactUs_contact_us_container__QQjPp {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 50px;
  margin: 50px;
}

.contactUs_contact_page_header__7UKoP {
  font-size: 20px;
  font-weight: bold;
}

.contactUs_contact_details_container__TZIhw {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-self: center;
  max-width: 400px;
}

.contactUs_contact_detail__i7pOb {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.contactUs_contact_detail__i7pOb > div:first-child {
  color: #505D66;
  font-weight: 600;
}

.contactUs_contact_details_section_container__bRTbY {
  display: flex;
  flex-direction: row;
  gap: 50px;
  width: 70%;
}

.contactUs_form_container__-880d {
  background-color: #F9FAFB;
  padding: 20px 50px 20px 50px;
  border-radius: 8px;
  width: 60%;
}

.contactUs_form_group__iF0N4 {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.contactUs_form_group__iF0N4 > label {
  font-weight: 600;
  font-size: 12px;
}

.contactUs_contact_form__ZyLu3 {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

@media (max-width: 768px) {
  .contactUs_contact_details_section_container__bRTbY {
    flex-direction: column;
  }
}`, "",{"version":3,"sources":["webpack://./src/views/ContactUsPage/contactUs.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,SAAA;EACA,YAAA;AACJ;;AAEA;EACI,eAAA;EACA,iBAAA;AACJ;;AAEA;EACI,aAAA;EACA,sBAAA;EACA,SAAA;EACA,kBAAA;EACA,gBAAA;AACJ;;AAEA;EACI,aAAA;EACA,sBAAA;EACA,SAAA;AACJ;AACI;EACI,cAAA;EACA,gBAAA;AACR;;AAGA;EACI,aAAA;EACA,mBAAA;EACA,SAAA;EACA,UAAA;AAAJ;;AAGA;EACI,yBAAA;EACA,4BAAA;EACA,kBAAA;EACA,UAAA;AAAJ;;AAGA;EACI,aAAA;EACA,sBAAA;EACA,SAAA;AAAJ;AAEI;EACI,gBAAA;EACA,eAAA;AAAR;;AAIA;EACI,aAAA;EACA,sBAAA;EACA,SAAA;AADJ;;AAIA;EACI;IACI,sBAAA;EADN;AACF","sourcesContent":[".contact_us_container{\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    justify-content: center;\r\n    gap: 50px;\r\n    margin: 50px;\r\n}\r\n\r\n.contact_page_header{\r\n    font-size: 20px;\r\n    font-weight: bold;\r\n}\r\n\r\n.contact_details_container{\r\n    display: flex;\r\n    flex-direction: column;\r\n    gap: 20px;\r\n    align-self: center;\r\n    max-width: 400px;\r\n}\r\n\r\n.contact_detail{\r\n    display: flex;\r\n    flex-direction: column;\r\n    gap: 10px;\r\n\r\n    >div:first-child{\r\n        color: #505D66;\r\n        font-weight: 600;\r\n    }\r\n}\r\n\r\n.contact_details_section_container{\r\n    display: flex;\r\n    flex-direction: row;\r\n    gap: 50px;\r\n    width: 70%;\r\n}\r\n\r\n.form_container{\r\n    background-color: #F9FAFB;\r\n    padding: 20px 50px 20px 50px;\r\n    border-radius: 8px;\r\n    width: 60%;\r\n}\r\n\r\n.form_group{\r\n    display: flex;\r\n    flex-direction: column;\r\n    gap: 10px;\r\n\r\n    >label{\r\n        font-weight: 600;\r\n        font-size: 12px;\r\n    }\r\n}\r\n\r\n.contact_form{\r\n    display: flex;\r\n    flex-direction: column;\r\n    gap: 20px;\r\n}\r\n\r\n@media (max-width: 768px){\r\n    .contact_details_section_container{\r\n        flex-direction: column;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contact_us_container": `contactUs_contact_us_container__QQjPp`,
	"contact_page_header": `contactUs_contact_page_header__7UKoP`,
	"contact_details_container": `contactUs_contact_details_container__TZIhw`,
	"contact_detail": `contactUs_contact_detail__i7pOb`,
	"contact_details_section_container": `contactUs_contact_details_section_container__bRTbY`,
	"form_container": `contactUs_form_container__-880d`,
	"form_group": `contactUs_form_group__iF0N4`,
	"contact_form": `contactUs_contact_form__ZyLu3`
};
export default ___CSS_LOADER_EXPORT___;
