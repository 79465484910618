// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cancellationPolicy_cancellation_policy_container__r58JO {
  padding: 50px;
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.cancellationPolicy_cancellation_policy_title__yfe5\\+ {
  align-self: center;
  font-size: 20px;
  font-weight: bold;
  color: black;
}

.cancellationPolicy_cancellation_subcontainer__7drNS {
  display: flex;
  gap: 20px;
  flex-direction: column;
  color: #515d66;
  line-height: 1.7;
  font-size: 14px;
}
.cancellationPolicy_cancellation_subcontainer__7drNS > div:first-child {
  font-size: 16px;
  font-weight: bold;
  color: black;
}

.cancellationPolicy_note__k2d2J {
  color: #515d66;
  line-height: 1.7;
  font-size: 16px;
}
.cancellationPolicy_note__k2d2J > b {
  color: black;
}`, "",{"version":3,"sources":["webpack://./src/views/PolicyPage/CancellationPolicy/cancellationPolicy.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AACJ;;AAGA;EACQ,kBAAA;EACA,eAAA;EACA,iBAAA;EACA,YAAA;AAAR;;AAGA;EACI,aAAA;EACA,SAAA;EACA,sBAAA;EAOK,cAAA;EACA,gBAAA;EACA,eAAA;AANT;AADI;EACI,eAAA;EACA,iBAAA;EACA,YAAA;AAGR;;AAKA;EAKI,cAAA;EACK,gBAAA;EACA,eAAA;AANT;AACI;EACI,YAAA;AACR","sourcesContent":[".cancellation_policy_container{\r\n    padding: 50px;\r\n    display: flex;\r\n    flex-direction: column;\r\n    gap: 50px;\r\n\r\n}\r\n\r\n.cancellation_policy_title{\r\n        align-self: center;\r\n        font-size: 20px;\r\n        font-weight: bold;\r\n        color: black;\r\n}\r\n\r\n.cancellation_subcontainer{\r\n    display: flex;\r\n    gap: 20px;\r\n    flex-direction: column;\r\n\r\n    >div:first-child{\r\n        font-size: 16px;\r\n        font-weight: bold;\r\n        color: black;\r\n    }\r\n         color: #515d66;\r\n         line-height: 1.7;\r\n         font-size: 14px;\r\n\r\n}\r\n\r\n.note{\r\n\r\n    >b{\r\n        color: black;\r\n    }\r\n    color: #515d66;\r\n         line-height: 1.7;\r\n         font-size: 16px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cancellation_policy_container": `cancellationPolicy_cancellation_policy_container__r58JO`,
	"cancellation_policy_title": `cancellationPolicy_cancellation_policy_title__yfe5+`,
	"cancellation_subcontainer": `cancellationPolicy_cancellation_subcontainer__7drNS`,
	"note": `cancellationPolicy_note__k2d2J`
};
export default ___CSS_LOADER_EXPORT___;
