import styles from './cancellationPolicy.module.scss'

const CancellationPolicy = () => {
  return (
  <div className={styles.cancellation_policy_container} >
      <div className={styles.cancellation_policy_title}>Cancellation Policy</div>
      <div className={styles.cancellation_subcontainer}>
        <div>Account Cancellation</div>
        <div>Customers can cancel their accounts at any time via their account page. Once we receive your request, ArrFineFoods.com will process the cancellation and confirm completion. If your account has been disabled for any reason, you will no longer be able to access it. To enable your account again, please contact us at info@arrfinefoods.com.</div>
      </div>
      <div className={styles.cancellation_subcontainer}>
        <div>Order Cancellation</div>
        <div>If you have changed your mind after the goods have been dispatched or delivered, notify us via email within 14 working days of receipt.</div>
      </div>
      <div className={styles.cancellation_subcontainer}>
        <div>Exceptions:</div>
        <ul>
          <li>Notify us in writing or contact our customer service team.</li>
          <li>Return the goods to our warehouse at your expense.</li>
          <li>We will inspect the returned goods and issue a refund within 3-5 working days after receiving them.</li>
        </ul>
      </div>
      <div className={styles.note}><b>Please Note:</b> Shipping charges are non-refundable. Refunds will only include the item price as per the invoice.</div>
    </div>
  )
}

export default CancellationPolicy