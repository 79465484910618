import styles from './failurePage.module.scss';

const FailurePage = () => {
    return (
        <div className={styles.container}>
        <h1>Payment Failure!</h1>
        <p>Your payment has been not been processed.</p>
        <p>Please try again</p>
        {/* You can add more details or actions here */}
      </div>
      )
}

export default FailurePage