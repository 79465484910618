import React from 'react'
import styles from './refundPolicy.module.scss'

const RefundPolicy = () => {
  return (
    <div className={styles.refund_policy_container}>
        <div className={styles.refund_policy_container_heading}>
         <div className={styles.refund_policy_container_title}>Refund Policy</div>
         <p>At ArrFineFoods, we prioritize your satisfaction with our products and services. If you are not satisfied and wish to return a product, we are happy to offer refunds or exchanges, provided the goods are in a fully resalable condition. Please call us at +44 1752 271528 or email us at info@arrfinefoods.com for guidance through the process.</p>
        </div>
        <div className={styles.refund_policy_subcontainer}>
            <div>
                <b>Important Note:</b>
                <div>The return process may vary depending on the nature and category of the product, as outlined in our cancellation policy and terms & conditions.</div>
            </div>
            <div>
                <div>The following items are excluded from our returns policy:</div>
                <ul>
                    <li>Vegetables</li>
                    <li>Frozen Items</li>
                    <li>Gift Vouchers</li>
                    <li>Beauty Products</li>
                    <li>Food Products</li>
                    <li>Food Gift Sets</li>
                    <li>Incense Products</li>
                </ul>
            </div>
        </div>
        <div>Refunds for these items will only be issued in accordance with your legal rights. If a product is faulty, damaged, or unfit for purpose, please contact us immediately via email or phone. You may be required to provide photographic evidence of any damaged goods.</div>
        <div>You may return other unopened goods within 3 days of receipt. Subject to inspection and acceptance, we will issue a refund or replacement as applicable.</div>
        <div>
            <b>Refund Method:</b>
            <ul>
                <li>Refunds will be issued via the original payment method.</li>
                <li>Postage and packaging charges will be deducted from refunds. For orders with free shipping, the actual shipping costs will be deducted.</li>
            </ul>
        </div>
        <div>
            <b>To request a refund or replacement:</b>
            <ol>
                <li>Contact us via email, phone, or live chat on our website.</li>
                <li>Return the goods at your expense for inspection.</li>
            </ol>
        </div>
        <div>ArrFineFoods is not liable for goods lost or damaged in transit, so we recommend obtaining proof of posting.</div>
        <div>If you receive any goods dispatched in error, please contact us within 2 days to arrange their return.</div>
     
    </div>
  )
}

export default RefundPolicy