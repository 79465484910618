// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button_button_style__BaEWb {
  background-color: #FFC220;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}`, "",{"version":3,"sources":["webpack://./src/components/Button/button.module.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;EACA,WAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,gBAAA;AACJ","sourcesContent":[".button_style{\r\n    background-color: #FFC220;\r\n    color: #000;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    font-weight: 600;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button_style": `button_button_style__BaEWb`
};
export default ___CSS_LOADER_EXPORT___;
