import {Card, Carousel } from "antd"
import { useContext, useState } from "react";
import styles from './productCarousal.module.scss';
import { Link } from "react-router-dom";
import { HeartFilled } from "@ant-design/icons";
import { CartContext, WishListContext } from "../../config/context/CartContext";
import Button from "../Button/Button";

interface ProductCarousalProps {
    productData : any,
    countPerRow: number
}

const ProductCarousal = ({productData,countPerRow}:ProductCarousalProps) => {

    const {addToCart} = useContext(CartContext);
    const {wishListItems,addTowishList,removeFromwishList} = useContext(WishListContext)

    const checkIteminWishList = (item:any) => {
        return wishListItems.some((sampleItem) => sampleItem.id === item.id)
    }

    const chunkArray = (arr:any, size:number) => {
        const result = [];
        for (let i = 0; i < arr.length; i += size) {
          result.push(arr.slice(i, i + size));
        }
        return result;
      };

      const chunks = chunkArray(productData, countPerRow);
      const [currentPage, setCurrentPage] = useState(0);

        // Function to handle next/prev navigation
        const nextPage = () => {
            if (currentPage < chunks.length - 1) {
            setCurrentPage(currentPage + 1);
            }
        };

        const prevPage = () => {
            if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
            }
        };


        return (
            <div className={styles.carousel}>
                  {/* Use class from the SCSS module */}
              {/* Render the current chunk */}
              <button onClick={prevPage} disabled={currentPage === 0}>
                  &#10094;
                </button>
              <div className={styles.carouselContainer}>
                {chunks[currentPage].map((item:any, index:number) => (
                  <div key={index} className={styles.carouselItem}>
                     <Link to={`/product/${item.id}`} className={styles.card_link}>
                            <Card hoverable={true} bordered={false} className={styles.homepage_product_card} cover={<div className={styles.card_image_background}>
                            <img alt='Product Image' src={item.imageUrl} className={styles.product_image}/>
                            <HeartFilled className={`${styles.like_icon} ${checkIteminWishList(item) ? styles.wishlist_icon : ''}`} onClick={() => {checkIteminWishList(item) ? removeFromwishList(item) : addTowishList(item)}}/>
                            </div>}>
                                <div>
                                    <div className={styles.best_seller_label}>Best Seller</div>
                                    <div className={styles.product_card_title}>{item.title}</div>
                                    <div>{item.description}</div>
                                    <div className={styles.product_price_container}>
                                        <div>{`\u00A3`} {item.price}</div>
                                        <Button onClick={() => {addToCart(item)}} text="Add Cart" className={styles.add_cart_button} iconType="shopcart"/>
                                    </div>
                                </div>
                            </Card>
                            </Link>
                  </div>
                ))}
              </div>
        

              
               
                <button onClick={nextPage} disabled={currentPage === chunks.length - 1}>
                   &#10095;
                </button>
             
            </div>
          );
}

export default ProductCarousal