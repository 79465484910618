// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./assets/fonts/Jupiter Condensed Alts W90 Rg.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./assets/fonts/Nada Regular.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html,body
{
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden; 
}

.App{
  height: 100%;
  display: flex;
  position: relative;

  /* Direction of the items, can be row or column */
  flex-direction: column;
}

.App::-webkit-scrollbar{
  display: none; 
}

@font-face {
  font-family: 'Jupiter';
  src: local('Jupiter'), url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('opentype');
}

@font-face {
  font-family: 'Nada';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('truetype');
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;;IAEI,WAAW;IACX,YAAY;IACZ,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,kBAAkB;;EAElB,iDAAiD;EACjD,sBAAsB;AACxB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,sBAAsB;EACtB,iFAAmG;AACrG;;AAEA;EACE,mBAAmB;EACnB,+DAA6D;AAC/D","sourcesContent":["html,body\r\n{\r\n    width: 100%;\r\n    height: 100%;\r\n    margin: 0px;\r\n    padding: 0px;\r\n    overflow-x: hidden; \r\n}\r\n\r\n.App{\r\n  height: 100%;\r\n  display: flex;\r\n  position: relative;\r\n\r\n  /* Direction of the items, can be row or column */\r\n  flex-direction: column;\r\n}\r\n\r\n.App::-webkit-scrollbar{\r\n  display: none; \r\n}\r\n\r\n@font-face {\r\n  font-family: 'Jupiter';\r\n  src: local('Jupiter'), url(./assets/fonts/Jupiter\\ Condensed\\ Alts\\ W90\\ Rg.otf) format('opentype');\r\n}\r\n\r\n@font-face {\r\n  font-family: 'Nada';\r\n  src: url(./assets/fonts/Nada\\ Regular.ttf) format('truetype');\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
